import loadable from "./loadable";
const Home = loadable(() => import("../pages/Home/index.js")); //首页
//装修服务
const kuaiwu = loadable(() => import("../pages/kuaiwu/index.js")); //快屋套餐
const lewu = loadable(() => import("../pages/lewu/index.js")); //乐屋个性化
const designTop = loadable(() => import("../pages/designTop/index.js")); //顶层别墅
const special = loadable(()=> import("../pages/special/index.js"));//专题活动old

//线下体验
const listStore = loadable(() => import("../pages/listStore/index.js")); //线下门店
const listConstruction = loadable(() =>
  import("../pages/listConstruction/index.js")
); //参观工地
//装修保障
const projectTeam = loadable(() => import("../pages/projectTeam/index.js")); //项目团队
const brandBlliance = loadable(() => import("../pages/brandBlliance/index.js")); //品牌联盟
const eProject = loadable(() => import("../pages/eProject/index.js")); //E+工程
const promiseJuran = loadable(() => import("../pages/promiseJuran/index.js")); //居然承诺
const protectionJuran = loadable(() =>
  import("../pages/protectionJuran/index.js")
); //数字化服务
//关于乐屋
const companyProfile = loadable(() =>
  import("../pages/companyProfile/index.js")
); //公司简介
const companyNews = loadable(() => import("../pages/companyNews/index.js")); //公司动态
const listOwnersVoice = loadable(() =>
  import("../pages/listOwnersVoice/index.js")
); //业主之声
const contactUs = loadable(() => import("../pages/contactUs/index.js")); //联系我们
//装修攻略
const decorationPre = loadable(() => import("../pages/decorationPre/index.js")); //装修前
const decorationZ = loadable(() => import("../pages/decorationZ/index.js")); //装修中
const decorationAfter = loadable(() =>
  import("../pages/decorationAfter/index.js")
); //装修后
const renovationq = loadable(() => import("../pages/renovationq/index.js")); //装修问答
const decorationEncy = loadable(() =>
  import("../pages/decorationEncy/index.js")
); //装修百科
const renovationDiary = loadable(() =>
  import("../pages/renovationDiary/index.js")
); //装修日记
//章鱼买手
const recruiting = loadable(() => import("../pages/recruiting/index.js")); //会员招募

//快速报价
const quickQuote = loadable(() => import("../pages/quickQuote/index.js")); //快速报价
//网站地图
const juranSiteMap = loadable(() => import("../pages/juranSiteMap/index.js"));
//装修案例 listCase
const listCase = loadable(() => import("../pages/listCase/index.js"));
const styleTest = loadable(() => import("../pages/styleTest/index.js")); //风格测试
const styleTestResult = loadable(() =>
  import("../pages/styleTestResult/index.js")
); //风格测试结果
const decorationQuestionDetails = loadable(() =>
  import("../pages/decorationQuestionDetails/index.js")
); //装修问答结果
const decorationStrategy = loadable(() =>
  import("../pages/decorationStrategy/index.js")
); //装修问答结果
const caseDetail = loadable(() => import("../pages/caseDetail/index.js")); //案例详情
const decorationDetails = loadable(() =>
  import("../pages/decorationDetails/index.js")
); //装修详情
//长尾词列表
const longTailWords = loadable(() => import("../pages/longTailWords/index.js"));
const designer = loadable(() => import("../pages/designer/index.js")); //设计师
const designerDetail = loadable(() =>
  import("../pages/designerDetail/index.js")
); //设计师详情
const listStoreDesigners = loadable(() =>
  import("../pages/listStoreDesigners/index.js")
); //线下门店门店设计师
const listStoreCase = loadable(() => import("../pages/listStoreCase/index.js")); //线下门店门店列表
const siteDetail = loadable(() => import("../pages/siteDetail/index.js")); //线下门店门店详情
const downloadApp=loadable(() => import("../pages/downloadApp/index.js"));//下载APP
const dynamicDetails=loadable(() => import("../pages/dynamicDetails/index.js"));//公司动态详情页
const webSiteActivity=loadable(() => import("../pages/webSiteActivity/index.js"));//专题活动页
const routes = [
  // {
  //   from: "/",
  //   to: "/home",
  //   component: Home,
  //   exact: true,
  // },
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    path: "/kuaiwu/:id",
    component: kuaiwu,
  },
  {
    path: "/lewu",
    component: lewu,
    exact: true,
  },
  {
    path: "/downloadApp",
    component: downloadApp,
    isHide:true
  }, {
    path: "/dynamicDetails/:id",
    component: dynamicDetails,
    isHide:true
  },
  {
    path: "/designTop",
    component: designTop,
  },
  {
    path:"/special/:id",
    component:special,
  },
  {
    path: "/listStore",
    component: listStore,
  },
  {
    path: "/listStoreDesigners/:id",
    component: listStoreDesigners,
  },
  {
    path: "/listStoreCase/:id",
    component: listStoreCase,
  },
  {
    path: "/listConstruction",
    component: listConstruction,
  },
  {
    path: "/projectTeam",
    component: projectTeam,
  },
  {
    path: "/brandBlliance",
    component: brandBlliance,
  },
  {
    path: "/eProject",
    component: eProject,
  },
  {
    path: "/promiseJuran",
    component: promiseJuran,
  },
  {
    path: "/protectionJuran",
    component: protectionJuran,
  },
  {
    path: "/companyProfile",
    component: companyProfile,
  },
  {
    path: "/companyNews",
    component: companyNews,
  },
  {
    path: "/listOwnersVoice",
    component: listOwnersVoice,
  },
  {
    path: "/decorationPre",
    component: decorationPre,
  },
  {
    path: "/decorationZ",
    component: decorationZ,
  },
  {
    path: "/decorationAfter",
    component: decorationAfter,
  },
  {
    path: "/renovationq",
    component: renovationq,
  },
  {
    path: "/decorationQuestionDetails/:id",
    component: decorationQuestionDetails,
  },
  {
    path: "/decorationEncy",
    component: decorationEncy,
  },
  {
    path: "/renovationDiary",
    component: renovationDiary,
  },
  {
    path: "/recruiting",
    component: recruiting,
  },
  {
    path: "/quickQuote",
    component: quickQuote,
  },
  {
    path: "/contactUs",
    component: contactUs,
  },
  {
    path: "/juranSiteMap",
    component: juranSiteMap,
  },
  {
    path: "/listCase",
    component: listCase,
  },
  {
    path: "/styleTest",
    component: styleTest,
  },
  {
    path: "/styleTestResult/:id",
    component: styleTestResult,
  },
  {
    path: "/decorationStrategy",
    component: decorationStrategy,
  },
  {
    path: "/caseDetail/:id",
    component: caseDetail,
  },
  {
    path: "/designer",
    component: designer,
  },
  {
    path: "/designerDetail/:id",
    component: designerDetail,
  },
  {
    path: "/decorationDetails/:id",
    component: decorationDetails,
  },
  {
    path: "/longTailWords/:id",
    component: longTailWords,
  },
  {
    path: "/siteDetail/:id",
    component: siteDetail,
  },
  {
    path: "/webSiteActivity/:id",
    component: webSiteActivity,
  },
];

export default routes;
