let api = {
  getHomeTDKData:"/api/websiteIndex/findAll",//首页TDK xsl
  getDesignerCaseList: "/api/website/getDesignerCaseList", //案例列表xsl
  getHouseType: "/api/website/getHouseType", //户型下拉列表
  getStoreListImg: "/api/websiteStoreAdvertising/findAll", //门店列表宣传图
  getDecorateStyle: "/api/website/getDecorateStyle", //装修风格宣传图
  getStyleTest: "/api/websiteInvestigation/findAll", //风格测试
  getStyleResult: "/api/websiteInvestigation/findAllAnswer", //风格测试结果
  getDesignerCaseInfo: "/api/website/getDesignerCaseInfo", //案例详情
  getCustomerCollect: "/api/customerCollect/save", //用户咨询信息保存
  getownersVoice: "/api/ownersVoice/getList", //查询业主之声列表 xsl
  getownersVoiceInfo: "/api/ownersVoice/getInfo", //评价详情
  getWorkSiteList: "/api/website/getWorkSiteList", //工地列表 xsl
  getCitySelect: "/api/website/citySelect", //省市区三级查询xsl
  getCityId: "/api/findUserCity", //查询省市Id xsl
  getWorkInfo: "/api/website/getWorkInfo", //工地详情
  getWorkStatusList: "/api/website/getWorkStatusList", //工地项目状态列表
  getProduceInfo: "/api/websiteProduce/getInfo", //产品详情
  findStoreAll: "/api/websiteStoreAdvertising/findStoreAll", //门店列表接口
  findNews: "/api/websiteNews/findAll", //获取新闻动态 xsl
  getwebsiteType: "/api/websiteType/findAllByTab", //装修干货分类
  websiteData: "/api/websiteData/findAllByTypeId", //装修干货列表 xsl
  getwebsiteDetailInfo: "/api/websiteData/findByDataId", //装修干货详情
  getDesigner: "/api/designer/findAll", //设计师列表
  getDesignerDetail: "/api/designer/findAllById", //设计师详情
  getDesignerDetailCaseList: "/api/designer/getDesignerCaseList", //设计师关联案例列表
  getWorkSiteDetailList: "/api/designer/getWorkSiteList", //设计师关联工地列表
  addReply: "/api/websiteData/addReply", //装修问答增加回复内容接口
  addCount: "/api/websiteData/addCount", //装修干货\装修问答增加查阅次数接口
  addQuestion: "/api/websiteData/addQuestion", //装修干货增加问题接口
  reversionList: "/api/websiteQuestion/reversionList", //装修干货回复列表接口
  reversionCount: "/api/websiteQuestion/reversionCount", //装修干货回复信息 点赞数 和不喜欢数
  websiteCarousel: "/api/websiteCarousel/findAll", //轮播图接口
  websiteLinks: "/api/websiteLinks/findAll", // 友情链接接口V1.0
  ownersPennant: "/api/website/ownersPennant/getList", // 业主锦旗接口v1.0 xsl
  websiteLongTailWord: "/api/websiteLongTailWord/findAll", // 长尾词列表V1.1 xsl
  getHotlabel: "/api/label/list", // 获取热门标签
  getHeatWords: "/api/heatWords/list", // 获取装修热词
  findAllDesignerByStoreId:
    "/api/websiteStoreAdvertising/findAllDesignerByStoreId", //获取门店下所有的设计师列表
  findAllCaseByStoreId: "/api/websiteStoreAdvertising/findAllCaseByStoreId", //获取门店下所有的设计师列表
  findAdvByStoreId: "/api/websiteStoreAdvertising/findAdvByStoreId", //门店详情页面 优惠活动接口
  findAllWorkByStoreId: "/api/websiteStoreAdvertising/findAllWorkByStoreId", //门店详情页面 门店下工地列表
  webSiteAdvAdmin: "/api/webSiteAdvAdmin/findAll", //活动专区下拉列表(顶部导航栏)
  getDesignerLeverList: "/api/website/getDesignerLeverList", //设计师级别
  getStoreList: "/api/website/getStoreList", //设计师级别
  getDesignerRangeList: "/api/website/getDesignerRangeList", //接单范围下拉列表
  findOneById: "/api/websiteType/findOneById", //装修干货分类查询具体id接口
  findAllPoster: "/api/websitePoster/findAll",//广告接口V
  getwebSiteAdmin:'/api/webSiteAdmin/findById',//右侧弹框 红/蓝展示
  findDesignerByRecommendId:"/api/designer/findDesignerByRecommendId",//通过推荐位获取具体设计师 首页
  getCpwebsiteProduce:'/api/websiteProduce/getList?categoryId=3',//
  getWebsiteCarouselStrategy:'/api/websiteCarouselStrategy/findAll',//装修攻略轮播图接口
  findByQuestionId : '/api/websiteData/findByQuestionId', //装修问答详情
  getwebsiteSystem:'/api/websiteSystem/findAll',//统计前端代码  xsl
  findTdkByLongWordId:'/api/websiteLongTailWord/findTdkByLongWordId',//根据长尾词id 获取长尾词信息
  findTitleByLabelId:'/api/websiteLongTailWord/findTitleByLabelId',//根据标签id 获取标签名字
  thematicActivities:'/api/webSiteActivity/getList',//查询专题活动列表
  projectDetails:'/api/webSiteActivity/getDetail',//查询专题活动详情
};

export default api;
