import { createStore, combineReducers } from "redux"; // 引入redux
import { counterReducer } from "./counterReducer"; // 这个是引入新建的reducer文件
import { cityIdReducer } from "./cityIdReducer"; // 这个是引入新建的reducer文件

const rootReducer = combineReducers({
  counterReducer,
  cityIdReducer
});
const iniitalState = {};

const store = createStore(rootReducer, iniitalState); //  创建store

export default store; // 抛出store
