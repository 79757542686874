export function NavAction(name) {
    return {
        type: "navbar",
        name,
    };
}

export function AddNavAction(data) {
    return {
        type: "addNav",
        data,
    };
}

export function setCityData(data) {
    return {
        type: "SET_CITYDATA",
        data,
    };
}

export function SortCase(data) {
  return {
    type: "sortCase",
    data,
  };
}
export function setHeadMenuList(data) {
    return {
        type: "SET_HEADMENU_LIST",
        data,
    };
}
