
const dssData={
    // cityData :{
    //     provinceId : '',
    //     cityId : '',
    //     cityName:'w',
    //     loading : 0
    // }
    cityData : sessionStorage.getItem('cityData') ? JSON.parse(sessionStorage.getItem('cityData')) : {}
}

export function cityIdReducer(state = dssData,action ){
    switch (action.type) {
        case 'SET_CITYDATA' :
            const newState2 = JSON.parse(JSON.stringify(state))
            newState2.cityData = action.data;
            return newState2;
        default :
            return state;
    }

}
