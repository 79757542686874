import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import React, {Component} from "react";
import ScrollToTop from "../components/ScrollToTop";
import Head from "../components/Head";
import {BackTop} from "antd";
import {up} from "../styles/images/home";
import Footer from "../components/Footer";
import Bottom from "../components/side/bottom/bottom";
import Rightred from "../components/side/right/right";
import {cityDataPinyin} from "../utils/cityData";
import {getKey, setBdTongj, setKey} from "../utils";

class RouterView extends Component {

    state = {
        isHide: true,
        cityPinyin: ''
    }

    componentDidMount() {
        this.setState({
            isHide: this.props.location.pathname !== "/downloadApp1"
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            isHide: nextProps.location.pathname !== "/downloadApp1"
        })
    }

    render() {
        let {routes} = this.props;
        let compList = routes.filter((item) => item.path);
        // let {cityData} = this.props;

        //判断url 是否存在城市
        let path = window.location.pathname;
        let cityPinyin = path.split('/')[1];
        let filterRes = cityDataPinyin.filter(function (c) {
            return c.pinyin.indexOf(cityPinyin) !== -1;
        })

        if (cityPinyin === '') {
            let pinyin = ''
            let cityData = getKey("cityData");
            if (cityData) {
                //根据定位数据修改url
                let result = cityDataPinyin.filter(function (c) {
                    return cityData.cityName.indexOf(c.name) !== -1;
                })
                pinyin = result ? result[0].pinyin : 'Beijing';
            }

            cityPinyin = pinyin
            window.location.href = window.location.origin + "/" + pinyin + path
        } else {
            if (filterRes.length > 0) { //存在城市拼音  修改当前定位数据
                const city = {
                    provinceId: filterRes[0].pid,
                    cityId: filterRes[0].id,
                    cityName: filterRes[0].name,
                    loading: 1,
                }
                // //设置script 统计代码
                setBdTongj(city.cityId, city.provinceId);
                setKey('cityData', city)
            } else { //不存在城市拼音
                let cityData = getKey("cityData");
                if (cityData) {
                    //根据定位数据修改url
                    let result = cityDataPinyin.filter(function (c) {
                        return cityData.cityName.indexOf(c.name) !== -1;
                    })
                    let pinyin = result ? result[0].pinyin : 'Beijing';
                    window.location.href = window.location.origin + "/" + pinyin + path
                }
            }

            return (
                <Switch>
                    <ScrollToTop>
                        {this.state.isHide && <Head/>}
                        <div style={{marginTop: `${this.state.isHide ? '3rem' : ''}`}} id="main_index">

                            {compList.map((item, i) => {
                                return (
                                    <Route
                                        key={i}
                                        path={'/' + cityPinyin + item.path}
                                        exact={item.exact}
                                        render={(props) => {
                                            return (
                                                <item.component
                                                    {...props}
                                                    child={item.children || []}
                                                    navLink={
                                                        item.children
                                                            ? item.children.filter((item) => item.path)
                                                            : []
                                                    }
                                                />
                                            );
                                        }}
                                    />

                                );
                            })}
                        </div>
                        {this.state.isHide && <BackTop>
                            <div><img className='up' src={up} alt=''/></div>
                        </BackTop>}
                        {this.state.isHide && this.RenderBottom()}
                        {this.state.isHide && this.RenderRight()}
                        {this.state.isHide && <Footer/>}
                        {window.location.pathname === "/home" && <Redirect to="/"/>}
                    </ScrollToTop>
                </Switch>
            );
        }

    }

    RenderBottom() {
        return <Bottom></Bottom>
    }

    RenderRight() {
        return <Rightred></Rightred>
    }
}

export default withRouter(RouterView)
