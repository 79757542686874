import React, {Component} from "react";
import "../../../node_modules/jquery/src/jquery";
import "bootstrap/dist/js/bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import {withRouter} from "react-router-dom";
import Dialog from "../Dialog/index";
import CitySelection from "../citySelection/index";
import {connect} from "react-redux";
import {AddNavAction, NavAction, setCityData, setHeadMenuList} from "../../store/actions";
import {getContactUs, gewebSiteMenu} from '../../request/index'
import {Modal} from 'antd'
import {Session} from "../../utils";
import {BigGift, close, gift, hot, listed_company, location, logo, phone,} from "../../styles/images/home/index";
import {cityDataPinyin} from "../../utils/cityData";
import {getKey} from "../../utils";

class Head extends Component {
    state = {
        giftVisible: false,
        contactUs: {},
        eventArea: [],
        navData: [],
        navData2: [],
        city: null,
        showAdv: true,
        advData: [],
        cityVisible: false,
        cityName: this.props.cityData.cityName,
        chooseName: '居然首页',
        navActive: null
    };

    giftSaveInfo(obj) {
        let source = "获取礼物";
        let sourceUrl = escape(document.location.href);//"/app";
        this.post(
            `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&source=${source}&sourceUrl=${sourceUrl}`,
            ""
        ).then((res) => {
            if (res.code === 0) {
                this.setState({
                    giftVisible: false,
                });
                Modal.success({
                    title: "已提交信息~",
                    okText: "确定",
                });
                this.setState({
                    name: "",
                    phone: "",
                });
            } else {
                Modal.error({
                    content: "手机号码格式不正确~",
                    okText: "确定",
                });
            }
        });

    }

    mouseOverMenu(name) {
        // console.log('移入了 哈哈')
        this.forceUpdate();
        // console.log(this.state.navData)
    }

    chooseNav(name, path, menu2) {
        this.setState({
            chooseName: name
        })
        // this.props.navbar(name);
        // let { navData } = this.state;
        // navData.map((item) => {
        //   item.active = false;
        //   if (item.name == name) {
        //     item.active = true;
        //   }
        // });
        // this.setState({
        //   navData: navData,
        // });
        if (path) {
            this.props.history.push({pathname: path});
        }
        // if (name == '风格测试' && menu2.length == 0) {
        //     Modal.error({
        //         title: '暂未开放',
        //         okText: '确定'
        //     })
        // }
        if (name == '活动专区' && menu2.length == 0) {
            Modal.error({
                title: '暂无活动',
                okText: '确定'
            })
        }
    }

    //跳转到报价页面
    goquickQuote() {
        this.props.history.push({
            pathname: "/quickQuote",
        });
    }

    //请求活动专区列表

    componentDidMount() {
        this.getContactUs()
        this.getAllMenuList();//获取所有的Menu菜单
        this.getWebSiteAdvAdmin();
        // this.getHeadMenuList();
        // this.findAllPoster();

        let that = this
        window.onscroll = function () {
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            const header_nav = document.getElementById('header_nav')
            let judgeFlag
            if (that.state.showAdv === true) {
                judgeFlag = 238
            } else {
                judgeFlag = 108
            }
            if (scrollTop > judgeFlag && header_nav) {
                header_nav.style.position = 'fixed'
            } else if (scrollTop < judgeFlag && header_nav) {
                header_nav.style.position = 'relative'
            }
        }

    }

    setCheckedState = (pathname) => { //修复刷新当前页面 跳转到首页
        const {navData} = this.state;
        const path = pathname.split('/')[2]

        if (path && navData) {
            navData.map((item, index) => {
                if (item.path) {
                    if (item.path.indexOf(path) != -1) {
                        this.setState({
                            chooseName: item.name
                        })
                    }
                } else if (item.menu) {
                    const i = index;
                    try {
                        item.menu && item.menu.map((m, a) => {
                            if (m.path.indexOf(path) != -1) {
                                this.setState({
                                    chooseName: navData[i].name
                                })
                            }
                        })
                    } catch (error) {
                        
                    }
                }
            })
        }
    }

    getWebSiteAdvAdmin() {
        this.get(`${this.api.webSiteAdvAdmin}?page=${1}&limit=${10}`).then(
            (res) => {
                if (res.code == 0 && res.data) {
                    this.props.addNav(res.data);
                }
            }
        );
    }

    closeButton() {
        this.setState({
            showAdv: false,
        })
        setTimeout(() => {
            this.setState({
                showAdv: true
            })
        }, 600000);
        document.getElementById("main_index").style.marginTop = '3rem'
    }

    getCityData() {
        return {
            provinceId: getKey('cityData').provinceId,
            cityId: getKey('cityData').cityId
        }
    }

    getContactUs() {
        getContactUs(this.getCityData()).then((res) => {
            if (res.data.code == 0) {
                if (res.data.data.length > 0) {
                    this.setState({
                        contactUs: res.data.data[0]
                    })
                }

            }
        })
    }

    RenderHeaderTop() {
        const {contactUs, city, showAdv, cityName} = this.state;
        let cityData = window.sessionStorage.getItem('cityData');
        cityData = JSON.parse(cityData)

        return (
            <div className='header_top'>
                <div className='header_top_left'>
                    <div className='change_address'>
                        <img className='address_icon' src={location} alt=''/>
                        <span className='address_name'>{cityData.cityName}</span>
                        <span
                            className='switch_address'
                            onClick={() => {
                                this.setState({cityVisible: true});
                            }}>[切换]</span>

                    </div>
                </div>
                <div className='header_top_right'>
                    <div className='preferential_activities'>
                        <img className='gift_icon' src={gift} alt=''/>
                        <span className='activities_span'>优惠活动</span>
                        <span className='online_consultation'>
              在线咨询客户经理享受额外惊喜
            </span>
                    </div>
                    <div className='consultation_hotline'>
                        <img className='phone_icon' src={phone} alt=''/>
                        <span className='hotline_phone'>咨询热线：{contactUs.phone}</span>
                    </div>
                </div>
            </div>
        );
    }

    RenderHeaderAdv() {
        const {advData, showAdv} = this.state
        if (advData) {
            return (
                <div className='header-adv' id="header_adv" onClick={() => {
                    this.closeButton()
                }} style={{display: (showAdv == true) ? 'block' : 'none'}}>
                    <a href={advData.openUrl}>
                        <img className="image-adv" src={advData.imgUrl} alt=""/>
                    </a>
                    <img src={close} className="close-button" alt=""/>
                </div>
            )
        }
    }

    RenderHeaderMid() {
        const {contactUs} = this.state;
        return (
            <div className='header_mid'>
                <div className='header_mid_left'>
                    <img className='header_logo' src={logo} alt=''/>
                </div>
                <div className='header_mid_right'>
                    <img className='listed_company' src={listed_company} alt=''/>
                    <div className='Hour_Hotline'>
                        <span className='Hour_Hotline_text'>24小时服务热线</span>
                        <span className='Hour_Hotline_phone'>{contactUs.phone}</span>
                    </div>
                    <img
                        className='BigGift'
                        src={BigGift}
                        alt=''
                        onClick={() => {
                            this.setState({giftVisible: true});
                        }}
                    />
                </div>
            </div>
        );
    }

    RenderHeaderNav() {
        const {eventArea, navData2, chooseName} = this.state;
        const navData = this.props.navData
        return (
            <ul className='nav nav-tabs header_nav' id="header_nav">
                {navData.map((item, index) => {
                    return (
                        <li
                            role='presentation'
                            className='dropdown header_nav_li'
                            key={item.name}
                            onClick={this.chooseNav.bind(this, item.name, item.path, item.menu2)}
                            onMouseOver={this.mouseOverMenu.bind(this, item.name)}
                        >
                            <a
                                className={`header_nav_link dropdown-toggle ${
                                    item.name == chooseName ? "header_nav_link_active " : ""
                                }`}
                                data-toggle='dropdown'
                                href='item.link'
                                aria-haspopup='true'
                                aria-expanded='false'
                            >
                                {item.name}
                                {/* {item.menu && <span className='caret'></span>} */}
                            </a>
                            {item.name == "活动专区" ? (
                                <img className='activity_hot' src={hot} alt=''/>
                            ) : (
                                ""
                            )}
                            {/*  onMouseEnter={this.menuMouseEnter.bind(this)} */}
                            <div>
                                {/*  className={`${item.name == chooseName ? "navlink_active" : ""}`} */}
                            {item.menu2 && (
                                <ul 
                                    className={item.menu2.length > 0 ? 'dropdown-menu' : ''}>
                                    {item.menu2.map((menuItem) => {
                                        return (
                                            <li
                                                key={menuItem.id}
                                                onClick={this.onChildMenu.bind(this, menuItem)}
                                            >
                                                {/*{menuItem.path ? (*/}
                                                {/*  <NavLink to={menuItem.path}>{menuItem.name}</NavLink>*/}
                                                {/*) : (*/}
                                                {/*  <a href={menuItem.url}>{menuItem.name}</a>*/}
                                                {/*)}*/}

                                                {menuItem.urls.indexOf('www') != -1 ? (
                                                    <a href={menuItem.urls}>{menuItem.name}</a>
                                                ) : (
                                                    <a href={menuItem.urls}>{menuItem.name}</a>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                            </div>
                        </li>
                    );
                })}
                <li className='header_nav_li'>
                    <div
                        onClick={() => {
                            this.goquickQuote();
                        }}
                        className='quick_quotation'
                    >
                        快速报价
                    </div>
                </li>
                {/* <li className='header_nav_li'>
                    <a className="jiancai_nav" href="http://www.jrlewu.com/Activity/2021/jctg-pc/">建材团购</a>
                    </li> */}
            </ul>
        );
    }

    getAllMenuList() {
        const navData = this.props.navData;
        navData.map((item, index) => {
            if (item.type) {
                const params = {
                    provinceId: this.props.cityData.provinceId,
                    cityId: this.props.cityData.cityId,
                    type: item.type
                }
                gewebSiteMenu(params).then((res) => {
                    item.menu2 = res.data.data;
                })
            }
        })
        this.props.setNavMenuList(navData)
        // setTimeout(() =>{
        this.setState({
            navData: this.props.navData,
            navData2: navData
        }, () => {
            this.setCheckedState(this.props.location.pathname)
        });
        // },1000)

    }

    getCityIdParams(cId, pId, cName) {
        this.setState({cityVisible: false})
        const params = {
            provinceId: pId,
            cityId: cId,
            cityName: cName,
            loading: Math.ceil(Math.random() * 10000000),
        }
        Session.setKey('cityData', params)
        this.props.setNeData(params)

        let filterRes = cityDataPinyin.filter(function (c) {
            return params.cityName.indexOf(c.name) !== -1;
        })

        let path = window.location.pathname
        let split = path.split('/');
        split[1] = filterRes[0] ? filterRes[0].pinyin : 'beijing';
        window.location.href = window.location.origin + split.join("/")
        // window.location.reload();
    }

    onChildMenu(item) {
        // console.log(item)

        this.addtdk(item.seotitle, item.describes, item.keyword);
    }

    addtdk(seotitle, describes, keyword) {
        let temp = document.getElementsByTagName('meta')
        while (temp.length) {
            temp[0].remove()
        }
        let t = document.createElement("meta")
        t.name = 'title'
        t.content = seotitle
        document.getElementsByTagName("head")[0].appendChild(t);
        if (keyword) {
            let k = document.createElement("meta")
            k.name = "key"
            k.content = keyword
            document.getElementsByTagName("head")[0].appendChild(k);
        }
        if (describes) {
            let d = document.createElement('meta')
            d.name = 'description'
            d.content = describes
            document.getElementsByTagName("head")[0].appendChild(d);
        }
        // //页面内title
        document.title = seotitle;
    }

    render() {
        const {giftVisible, cityVisible} = this.state;
        return (
            <div className='headContainer' style={{marginBottom: '-3rem'}}>
                <div className="header-title"/>
                {this.RenderHeaderTop()}
                <div className='header_line'/>
                {this.RenderHeaderAdv()}
                {this.RenderHeaderMid()}
                <div className='header_line'/>
                {this.RenderHeaderNav()}
                <Dialog
                    type='gift'
                    visible={giftVisible}
                    cancelClick={() => this.setState({giftVisible: false})}
                    btnTxt={["立即获取"]}
                    saveInfo={this.giftSaveInfo.bind(this)}
                />
                <CitySelection
                    visible={cityVisible}
                    cancelClick={() => this.setState({cityVisible: false})}
                    getCityData={this.getCityIdParams.bind(this)}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        navData: state.counterReducer.navData,
        cityData: state.cityIdReducer.cityData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addNav: (data) => {
            dispatch(AddNavAction(data));
        },
        navbar: (name) => {
            dispatch(NavAction(name));
        },
        setNeData: (params) => {
            dispatch(setCityData(params));
        },
        setNavMenuList: (data) => {
            dispatch(setHeadMenuList(data))
        }
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Head));
