import React, {Component} from 'react'
import '../../../node_modules/jquery/src/jquery'
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.scss'
import Dialog from "../../components/Dialog/index";
import {Modal} from "antd";
import {connect} from "react-redux";
import {getContactUs, getQrcodeInfo} from '../../request/index';

import {footerBg, footerLogo} from '../../styles/images/home/index'
import {getKey} from "../../utils";

class Footer extends Component {
    state = {
        visible: false,
        contactUs: {},
        codeOneName: '',
        codeOneImg: '',
        codeTwoName: '',
        codeTwoImg: ''
    };

    componentDidMount() {
        this.getContactUs()
        this.getQrcode()
    }

    getCityData() {
        return {
            provinceId: getKey('cityData').provinceId,
            cityId: getKey('cityData').cityId
        }
    }

    getContactUs() {
        getContactUs(this.getCityData()).then((res) => {
            if (res.data.code == 0) {
                if (res.data.data.length > 0) {
                    this.setState({
                        contactUs: res.data.data[0]
                    })
                }

            }
        })
    }

    //查询首页二维码
    getQrcode() {
        getQrcodeInfo(this.getCityData()).then((res) => {
            let result = res.data
            if (result.code == 0) {
                if (result.data) {
                    this.setState({
                        codeOneName: result.data.code_one_name,
                        codeOneImg: result.data.code_one_img,
                        codeTwoName: result.data.code_two_name,
                        codeTwoImg: result.data.code_two_img
                    })
                } else {
                    this.setState({
                        codeOneName: '',
                        codeOneImg: '',
                        codeTwoName: '',
                        codeTwoImg: ''
                    })
                }
            }
        })
    }


    saveInfo(obj) {
        let source = "立即预约";
        let sourceUrl = `page/${this.props.sourceUrl}`;
        this.post(
            `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}`,
            ""
        ).then((res) => {
            if (res.code === 0) {
                this.setState({
                    visible: false,
                });
                Modal.success({
                    title: "已提交信息~",
                    okText: "确定",
                });
            }
        });
    }

    render() {
        const {contactUs, visible} = this.state;
        if (contactUs) {
            return (
                <div
                    className='FooterContainer'
                    style={{backgroundImage: 'url(' + footerBg + ')'}}
                >
                    <img className='footerLogo' src={footerLogo} alt=''/>
                    <ul className='footer_link_list'>
                        <li className='footer_link_item'>
                            <p className='footer_link_title'>关于乐屋</p>
                            <a className='footer_link footer_link1' href='/companyProfile'>
                                公司简介
                            </a>
                            <a className='footer_link footer_link1' href='/contactUs'>
                                联系我们
                            </a>
                            <a className='footer_link footer_link1' href='/juranSiteMap'>
                                网站地图
                            </a>
                            <a className='footer_link footer_link1' href='#'>
                                集团官网
                            </a>
                        </li>
                        <li className='footer_link_item'>
                            <p className='footer_link_title'>装修服务</p>
                            <a className='footer_link footer_link1' href='/kuaiwu'>
                                快屋整装
                            </a>
                            <a className='footer_link footer_link1' href='/lewu'>
                                乐屋个性化
                            </a>
                            <a className='footer_link footer_link1' href='/designTop'>
                                顶层别墅
                            </a>
                            <a className='footer_link footer_link1' href='/oldRoom'>
                                老房改造
                            </a>
                        </li>
                        <li className='footer_link_item'>
                            <p className='footer_link_title'>装修保障</p>
                            <a className='footer_link footer_link1' href='/brandBlliance'>
                                品牌联盟
                            </a>
                            <a className='footer_link footer_link1' href='/eProject'>
                                E+工程体系
                            </a>
                            {/* <a className='footer_link footer_link1' href='/promiseJuran'>
                                居然承诺
                            </a> */}
                        </li>

                        <li className='footer_link_contact'>
                            <p className='footer_link_title'>联系我们</p>
                            <p className='home_decoration_consultation'>
                                家装咨询：{contactUs.phone}
                            </p>
                            <p className='company_address'>
                                公司地址：{contactUs.address}
                            </p>
                            {/* <span>查看更多》</span>  */}
                            <a className='footer_link1' href='/quickQuote'>
                                免费家装报价
                            </a>
                            <span className='footer_link footer_link2' to='#'
                                  onClick={() => this.setState({visible: true})}>
              免费户型规划
            </span>
                        </li>

                        <li className='footer_codes'>
                            <div className="footer-code-image">
                                {this.state.codeOneImg ?
                                    <img className='footer_code' src={this.state.codeOneImg} alt=''/> : ''}
                                {this.state.codeTwoImg ?
                                    <img className='footer_code' src={this.state.codeTwoImg} alt=''/> : ''}
                            </div>
                            <div className="footer-text">
                                <p className="footer-text-1">{this.state.codeOneName}</p>
                                <p>{this.state.codeTwoName}</p>
                            </div>
                        </li>
                    </ul>

                    <div className='line'></div>
                    <p className='footer_copyright'>
                    北京居然乐屋装饰有限公司版权所有 © 2003-2022 All RIGHTS RESERVED.
                        <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备14008453号-4</a>
                    </p>
                    <p className='footer_disclaimers'>
                        免责声明：本网站内容部分来源于网络，如权利人发现存在误传其作品情形，请及时与本站联系
                    </p>
                    <Dialog
                        type="designer"
                        visible={visible}
                        cancelClick={() => this.setState({visible: false})}
                        btnTxt={["立即预约"]}
                        saveInfo={this.saveInfo.bind(this)}
                    />
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        cityData: state.cityIdReducer.cityData
    };
}

export default connect(mapStateToProps, null)(Footer)
