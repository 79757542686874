import React, { Component } from "react";
import { Input, Button, Modal } from "antd";
import { jisuan, shangla, xiala } from "../../../styles/images/home/index";
import "./bottom.scss";
import $ from "../../../../node_modules/jquery";
import { connect } from 'react-redux';
class Bottom extends Component {
  state = {
    imgIcon: shangla,
    name: "",
    phone: "",
    village: "",
    area: "",
    cityId:this.props.cityData.cityId,
    provinceId:this.props.cityData.provinceId,
  };

    inputChange(type, e) {
        if (type === 1) {
            this.setState({
                name: e.target.value,
            });
        } else if (type === 2) {
            this.setState({
                phone: e.target.value,
            });
        } else if (type === 3) {
            this.setState({
                village: e.target.value,
            });
        } else {
            this.setState({
                area: e.target.value,
            });
        }
    }

    //用户咨询信息保存
    getCustomerCollect(tit) {
        const { name, area, village, phone,cityId,provinceId} = this.state;
        const sourceUrl = "home";
        if (phone === "") {
            Modal.error({
                title: "手机号码不能为空~",
                okText: "确定",
            });
        } else if (/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone)) {
            this.post(
                `${this.api.getCustomerCollect}?username=${name}&phone=${phone}&address=${village}&housingArea=${area}&source=${tit}&sourceUrl=${sourceUrl}&cityId=${cityId}&provinceId=${provinceId}`,
                ""
            ).then((res) => {
                if (res.code === 0) {
                    Modal.success({
                        content: "已提交信息",
                        okText: "确定",
                    });
                    this.setState({
                        name: "",
                        area: "",
                        phone: "",
                        village: "",
                    });
                }
            });
        } else {
            Modal.error({
                content: "手机号码格式不正确~",
                okText: "确定",
            });
        }
    }

    iconHome() {
        if (this.state.imgIcon == shangla) {
            this.setState(
                {
                    imgIcon: xiala,
                });
            document.getElementById('bottom_container').style.bottom = '-0.1rem'

        } else {
            this.setState({
                imgIcon: shangla,
            });
            document.getElementById('bottom_container').style.bottom = '-1.4rem'
        }
    }

    RenderBottom() {
        return (
            <div className="bottom">
                <div className="bottom-top" id="bottomTop">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <span className="text text_front">
                                        <img id="jisuan" className="icon jisuan" src={jisuan} />
                                        <span className="text text_color">算一算</span>我家装修需要多少钱</span>
                                </td>
                                <td>
                                    <img
                                        onClick={() => {
                                            this.iconHome();
                                        }}
                                        id="shangla"
                                        className="icon shangla"
                                        src={this.state.imgIcon}
                                    />
                                    <span className="text text_last">(*提前了解详细预算，报价全透明)</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="bottom_form" id="bottom_form">
                    <div className="form-center">
                        <div className="form-input-block">
                            <ul>
                                <li>
                                    <Input
                                        placeholder="请输入您的姓名："
                                        value={this.state.name}
                                        type="text"
                                        onChange={this.inputChange.bind(this, 1)}
                                    />
                                </li>
                                <li>
                                    <Input
                                        placeholder="请输入您的联系方式："
                                        value={this.state.phone}
                                        type="number"
                                        onChange={this.inputChange.bind(this, 2)}
                                    />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <Input
                                        placeholder="请输入您的小区名称："
                                        value={this.state.village}
                                        type="text"
                                        onChange={this.inputChange.bind(this, 3)}
                                    />
                                </li>
                                <li>
                                    <Input
                                        placeholder="请输入您的房屋面积："
                                        value={this.state.area}
                                        type="num"
                                        onChange={this.inputChange.bind(this, 4)}
                                    />
                                </li>
                            </ul>
                        </div>
                        <div className="form-button">
                            <Button
                                type="primary"
                                id="baojia"
                                onClick={this.getCustomerCollect.bind(
                                    this,
                                    "一键获取装修报价"
                                )}
                            >
                                一键获取
                                <br />
                                装修报价
                            </Button>
                        </div>
                        <div className="form-right">
                            <div className="mianfei">免费报价电话</div>
                            <div className="phone">400-686-1997</div>
                        </div>
                    </div>
                    <div className="form_con">
                        <table className="form_table">
                            <tbody className="tbody">
                                <tr className="firsttr">
                                    <td>

                                    </td>
                                    <td>

                                    </td>
                                    <td rowSpan="2">

                                    </td>
                                    <td colSpan="2">
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                    </td>
                                    <td>

                                    </td>
                                    <td colSpan="2">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        );
    }

    render() {
        return <div className="footer-bottom_container" id="bottom_container">{this.RenderBottom()}</div>;
    }

    //底部下拉滑动事件

}

function mapStateToProps(state) {
  return {
    cityData: state.cityIdReducer.cityData
  };
}
export default connect(mapStateToProps,null)(Bottom);
