import React, {Component} from "react";
import { Modal, Input } from "antd";
import "./index.scss";
export default class index extends Component{
    constructor(props) {
        super(props);
        this.state={
            visible:true,
            provinceData:[],
            cityData:[],
            pId:'2',
            cId:'',

        }
    }
    componentDidMount() {
        this.getAllProvinces()
        this.getCityById('2')
    }

    getCityById(provinceId) {
        this.setState({pId:provinceId})
        this.get(`${this.api.getCitySelect}?id=${provinceId}`, "").then((res) => {
            if (res.code === 0) {
                this.setState({
                    cityData: res.data,
                });
            }
        });
    }
    getAllProvinces() {
        this.get(`${this.api.getCitySelect}`, "").then((res) => {
            if (res.code === 0) {
                this.setState({
                    provinceData: res.data,
                });
            }
        });
    }
    handleCancel () {
        this.setState({
            visible:false
        })
    }
    handleCityClick (cId,cName) {
        const {pId} =this.state;
        this.props.getCityData(cId,pId,cName)
    }
    render () {
        // const {visible} =this.state;
        const {cancelClick,visible} =this.props;
        return (
            <div className="city_box1">
                <Modal
                    title="城市选择"
                    style={{ top: 50 }}
                    width={520}
                    visible={visible}
                    onCancel={cancelClick}
                    footer={[]}
                >
                    <div className='wrapper'>
                        <div className="p_box">
                            {
                                this.state.provinceData.map((item,index)=>{
                                    return (
                                        <span
                                            key={item.id}
                                            onClick={this.getCityById.bind(this,item.id)}
                                        >{item.name}</span>
                                    )
                                })
                            }
                        </div>
                        <div className="c_box">
                            {
                                this.state.cityData.map((item,index)=>{
                                    return (
                                        <span
                                            key={item.id}
                                            onClick={this.handleCityClick.bind(this,item.id,item.name)}
                                        >{item.name}</span>
                                    )
                                })
                            }
                        </div>
                    </div>

                </Modal>
            </div>

        )
    }


}