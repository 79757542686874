import { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { NavAction } from "../../store/actions";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // this.props.navbar(this.props.location.pathname);
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

function mapStateToProps(state) {
  return {
    navData: state.counterReducer.navData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navbar: (name) => {
      dispatch(NavAction(name));
    },
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ScrollToTop)
);
