const iniitalState = {
  navData: [
    {
      name: "乐屋首页",
      active: true,
      path: "/",
    },
    {
      name: "活动专区",
      active: false,
      menu: [],
      menu2: [],
      type:'1'
    },
    {
      name: "装修服务",
      active: false,
      type:'2',
      menu: [
        { id: 1, name: "乐屋个性化", path: "/lewu" },
        { id: 2, name: "899整装", path: "/kuaiwu/8" },
        { id: 3, name: "顶层别墅", path: "/designTop" },
        { id: 4, name: "老房装修", path: "/kuaiwu/13" },
        { id: 5, name: "厨房翻新", path: "/kuaiwu/10" },
        { id: 6, name: "卫生间翻新", path: "/kuaiwu/12" },
        { id: 7, name: "居然焕新", path: "/kuaiwu/9" },
      
        
      ],
       menu2: [],
    },
    {
      name: "装修案例",
      active: false,
      path: "/listCase",
    },
    {
      name: "选设计师",
      active: false,
      path: "/designer",
    },
    {
      name: "风格测试",
      active: false,
      path: "/styleTest",
    },
    {
      name: "线下体验",
      active: false,
      type:'4',
      menu: [
        { id: 1, name: "线下门店", path: "/listStore" },
        { id: 2, name: "参观工地", path: "/listConstruction" },
      ],
      menu2: [],
    },
    {
      name: "装修保障",
      active: false,
      type:'5',
      menu: [
        { id: 1, name: "项目团队", path: "/projectTeam" },
        { id: 2, name: "品牌联盟", path: "/brandBlliance" },
        { id: 3, name: "E+工程体系", path: "/eProject" },
        { id: 4, name: "居然承诺", path: "/promiseJuran" },
        { id: 5, name: "数字化服务", path: "/protectionJuran" },
      ],
      menu2: [],
    },
    {
      name: "装修攻略",
      active: false,
      type:'6',
      menu: [
        { id: 1, name: "装修攻略", path: "/decorationStrategy" },
        { id: 2, name: "装修前", path: "/decorationPre" },
        { id: 3, name: "装修中", path: "decorationZ" },
        { id: 4, name: "装修后", path: "/decorationAfter" },
        { id: 5, name: "装修问答", path: "/renovationq" },
        { id: 6, name: "装修百科", path: "/decorationEncy" },
        { id: 7, name: "装修日记", path: "/renovationDiary" },
      ],
      menu2: [],
    },
    {
      name: "关于乐屋",
      active: false,
      type:'7',
      menu: [
        { id: 1, name: "公司简介", path: "/companyProfile" },
        { id: 2, name: "公司动态", path: "/companyNews" },
        { id: 3, name: "业主之声", path: "/listOwnersVoice" },
        { id: 4, name: "联系我们", path: "/contactUs" },
        { id: 5, name: "下载APP", path: "/downloadApp" },
      ],
      menu2: [],
    },
  ],
  caseSortData: [
    {
      id: 1,
      name: "最新",
      type: "desc",
    },
    {
      id: 2,
      name: "人气",
      type: "desc",
    },
    {
      id: 3,
      name: "面积",
      type: "desc",
    },
  ],
};

//  抛出counterReducer这个方法
export function counterReducer(state = iniitalState, action) {
  switch (action.type) {
    case "navbar":
      const { name } = action;
      state.navData.map((item) => {
        item.active = false;
        if (name === item.name) {
          item.active = true;
        } else {
          item?.menu?.map((menuData) => {
            if (menuData.path === `/${name.split("/")[1]}`) {
              item.active = true;
            }
          });
        }
      });
      return {
        navData: state.navData,
      };
    case "addNav":
      state.navData.map((item) => {
        item.active = false;
        if ("活动专区" === item.name) {
          item.menu = action.data;
        }
      });

      return {
        navData: state.navData,
      };
    // case "sortCase":
    //   state.caseSortData = action.data;
    //   return { caseSortData: state.caseSortData };
    case 'SET_HEADMENU_LIST' : //设置List
      const newState = JSON.parse(JSON.stringify(state))
      newState.navData = action.data;
      return newState;
    default:
      return state;
  }
}
