import React, {Component} from 'react'
import './App.scss'
import {BrowserRouter} from 'react-router-dom'
import RouterView from './router/index'
import routes from './router/config'
import Bottom from './components/side/bottom/bottom'
import Rightred from './components/side/right/right'
import {connect} from "react-redux";
import {cityDataPinyin} from "./utils/cityData";
import {setLocation, setBdTongj, setKey} from "./utils";
import store from './store'
import {setCityData} from './store/actions'


class App extends Component {
    state = {
        top: '3rem',
        // cityId: this.props.cityData.cityId,
        // provinceId: this.props.cityData.provinceId,
    }

    constructor(props) {
        super(props);
        
        let path = window.location.pathname;
        let cityPinyin = path.split('/')[1]; 
        let filterRes = cityDataPinyin.filter(function (c) {
            return c.pinyin.indexOf(cityPinyin) !== -1;
        })

        // 路由中不存在城市拼音或没有在字典中查到，按地理位置信息获取
        if (cityPinyin === '' || filterRes.length === 0) {
            setLocation(); //定位当前城市 并存储 重新加载
        } else {
            const city = {
                provinceId: filterRes[0].pid,
                cityId: filterRes[0].id,
                cityName: filterRes[0].name,
                loading: 1,
            }
            store.dispatch(setCityData(city))
            setBdTongj(city.cityId, city.provinceId);
            setKey('cityData', city)
        }
    }

    changeCityData() {
        let cityData = window.sessionStorage.getItem("cityData")
        cityData = JSON.parse(cityData)
        if (cityData){
            this.setState({
                cityId: cityData.cityId,
                provinceId: cityData.provinceId
            })
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.resizeListener)
        this.resizeListener()
        this.changeCityData()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeListener)
        // window.sessionStorage.removeItem('cityData')
    }

    resizeListener() {
        // 定义设计图的尺寸 1920
        let designSize = 1920
        // 获取 html 元素
        let html = document.documentElement
        // 定义窗口的宽度
        let clientW = html.clientWidth
        let sizeNum = 100
        if (clientW <= 1360) {
            sizeNum = 130
        } else if (clientW <= 1400) {
            sizeNum = 120
        } else if (clientW <= 1600) {
            sizeNum = 110
        }
        // html 的fontsize 大小
        let htmlRem = (clientW * sizeNum) / designSize
        html.style.fontSize = htmlRem + 'px'
    }

    RenderBottom() {
        return <Bottom></Bottom>
    }

    RenderRight() {
        return <Rightred></Rightred>
    }

    render() {
        //当城市id 数据保存后在渲染组件
        // const isShow = this.props.cityData.loading ? true : false
        return (
            <div>
                {
                    this.props.cityData.loading ? <BrowserRouter>
                        <RouterView cityData={this.props.cityData} routes={routes} />
                    </BrowserRouter> : ''
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        cityData: state.cityIdReducer.cityData
    };
}

export default connect(mapStateToProps, null)(App);
