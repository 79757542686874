import axios from "axios";
import {getRootUrl} from "./http";

let baseURL = getRootUrl();
//轮播接口Home
export const getSwiperList = (params) => {
    return axios.get(baseURL + "/api/websiteCarousel/findAll", {params: params});
};
//联系我们 V1.0
export const getContactUs = (params) => {
    return axios.get(baseURL + "/api/websiteOwer/findAll", {params: params});
};
//公司简介 V1.0
export const getCompanyIntroduction = (params) => {
    return axios.get(baseURL + "/api/websiteCompany/findAll", {params: params});
};

//广告位接口 V1.3
export const getwebsitePoster = (params) => {
    return axios.get(baseURL + "/api/websitePoster/findAll", {params: params});
};
//心选广告位 v1.3
export const getwebsitePosterChoose = (params) => {
    return axios.get(baseURL + "/api/websitePosterChoose/findAll", {params: params});
};
//首页栏目管理V1.0
export const getwebsiteTab = (params) => {
    return axios.get(baseURL + "/api/websiteTab/findAll", {params: params});
};
//产品分类列表V1.0 (首页 我们的服务)
export const getwebsiteProduceCategory = (params) => {
    return axios.get(baseURL + "/api/websiteProduceCategory/getListForPc", {params: params});
};
//PC端菜单管理 V1.0
export const gewebSiteMenu = (params) => {
    return axios.get(baseURL + "/api/webSiteMenu/findAll", {params: params});
};


//合作商管理
export const getwebsiteBusiness = () => {
    return axios.get(baseURL + "/api/websiteBusiness/findAll");
};
//业主之声接口
export const getownerList = () => {
    return axios.get(baseURL + "/api/ownersVoice/getList");
};

export const getStyleTest = () => {
    return axios.get(baseURL + "/api/websiteInvestigation/findAll");//风格测试
};

export const getStyleResult = () => {
    return axios.get(baseURL + "/api/websiteInvestigation/findAllAnswer");//风格测试结果
};

export const getStoreList = () => {
    return axios.get(baseURL + "/api/websiteStoreAdvertising/findStoreAll");//门店列表
};

export const getDesignerCaseList = () => {
    return axios.get(baseURL + "/api/website/getDesignerCaseList");//案例列表
};

//查询门店宣传图 接口
export const websiteStoreAdvertising = () => {
    return axios.get(baseURL + "/api/websiteStoreAdvertising/findAll");
};
//查询门店列表接口
export const websiteStore = () => {
    return axios.get(baseURL + "/api/websiteStoreAdvertising/findStoreAll");
};

//查询公司动态列表 V1.2
export const websiteNews = () => {
    return axios.get(baseURL + "/api/websiteNews/findAll");
};
//查询业主详情内推荐列表
export const websiteData = () => {
    return axios.get(baseURL + "/api/websiteData/findAllByTypeId");
};
//门店详情页面 门店下所有的设计师列表
export const findAllDesignerByStoreId = () => {
    return axios.get(baseURL + "/api/websiteStoreAdvertising/findAllDesignerByStoreId")
}
//门店详情页面 门店下所有的案例列表
export const findAllCaseByStoreId = () => {
    return axios.get(baseURL + " /api/websiteStoreAdvertising/findAllCaseByStoreId")
}
//门店详情页面 优惠活动接口
export const findAdvByStoreId = () => {
    return axios.get(baseURL + "/api/websiteStoreAdvertising/findAdvByStoreId")
}
//门店详情页面 门店下工地列表
export const findAllWorkByStoreId = () => {
    return axios.get(baseURL + "/api/websiteStoreAdvertising/findAllWorkByStoreId")
}
//长尾词
export const websiteLongTailWord = () => {
    return axios.get(baseURL + "/api/websiteLongTailWord/findAll")
}
//查询专题活动列表
export const thematicActivities = () => {
    return axios.get(baseURL + "/api/webSiteActivity/getList")
}
//查询专题活动详情
export const projectDetails = () => {
    return axios.get(baseURL + "/api/webSiteActivity/getDetail")
}
//查询首页二维码
export const getQrcodeInfo = (params) => {
    return axios.get(baseURL + "/api/website/qrcode/getInfo", {params: params})
}
