import React, { Component } from "react";
import {
  guanbi,
  baojia,
  liangfang,
  kefu,
  dianhua,
} from "../../../styles/images/home/index";
import { Modal } from "antd";
import "./right.css";
import Dialog from "../../Dialog";
import { withRouter } from "react-router-dom";
class Rightred extends Component {
  state = {
    rightNav: [
      {
        id: 1,
        name: "环保量房",
        active: false,
        path: "",
      },
      {
        id: 2,
        name: "户型规划",
        active: false,
        path: "",
      },
      {
        id: 3,
        name: "0元报价",
        active: false,
        path: "",
      },
      {
        id: 4,
        name: "在线客服",
        active: false,
        path: "",
      },
      {
        id: 5,
        name: "一站式整装",
        active: false,
        path: "",
      },
    ],
    rightBlue: [
      {
        id: 1,
        name: "在线客服",
        active: false,
        path: "",
        src: kefu,
      },
      {
        id: 2,
        name: "电话咨询",
        active: false,
        path: "",
        src: dianhua,
      },
      {
        id: 3,
        name: "免费量房",
        active: false,
        path: "",
        src: liangfang,
      },
      {
        id: 4,
        name: "快速报价",
        active: false,
        path: "",
        src: baojia,
      },
    ],
    roomVisible: false,
    designerVisible: false,
    priceVisible: false,
  };

  SaveInfo(obj) {
    const { source } = this.state;
    let sourceUrl = escape(document.location.href);//"/home";
    this.post(
      `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}`,
      ""
    ).then((res) => {
      if (res.code === 0) {
        this.setState({
          roomVisible: false,
          designerVisible: false,
          priceVisible: false,
        });
        Modal.success({
          title: "已提交信息~",
          okText: "确定",
        });
      }
    });
  }

  open53() {
    var _53 = window.$53.createApi();
    _53.push("cmd", "kfclient");
    _53.push("type", "popup");
    _53.query();
  }

  goUrl(id) {
    if (id === 1) {
      //环保量房
      this.setState({ roomVisible: true, source: "环保量房" });
    } else if (id === 2) {
      //户型规划
      this.setState({ designerVisible: true, source: "户型规划" });
    } else if (id === 3) {
      //0元报价
      this.setState({ priceVisible: true, source: "0元报价" });
    } else if (id === 4) {
      //在线客服
      this.open53();
    } else {
      //一站式整装
      this.props.history.push({
        pathName: "/kuaiwu/8",
      });
    }
  }
  goUrll(id) {
    if (id === 1) {
      this.open53()
      //在线客服
    } else if (id === 2) {
      //电话咨询
      this.setState({ designerVisible: true, source: "电话咨询" });
    } else if (id === 3) {
      //免费量房
      this.setState({ roomVisible: true, source: "环保量房" });
    } else if (id === 4) {
      //快速报价
      this.setState({ priceVisible: true, source: "0元报价" });
      // this.open53();
    } else {
      //一站式整装
      this.props.history.push({
        pathName: " /kuaiwu/8",
      });
    }
  }
  RenderRightRed() {
    const { rightNav } = this.state;
    return (
      <div className='right_top'>
        <img id='guanbi' className='guanbi' src={guanbi}></img>
        <ul className='rightNav'>
          {rightNav.map((item) => {
            return (
              <li className='rightRed_li' key={item.id}>
                <div className='right_con'>
                  <a
                    className='right_text'
                    onClick={this.goUrl.bind(this, item.id)}
                  >
                    {item.name}
                  </a>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  RenderRightBlue() {
    const { rightBlue } = this.state;
    return (
      <div className='rightBlue'>
        <ul className='rightBlue_con'>
          {rightBlue.map((item) => {
            return (
              <li className='rightBlue_li' key={item.id} onClick={this.goUrll.bind(this, item.id)}>
                <div className='rightBlue_text'>
                  <img className='rightBlue_img' src={item.src}></img>
                  <div className='rightBlue_A' to={item.path} >
                    {item.name}{" "}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  render() {
    const { roomVisible, priceVisible, designerVisible } = this.state;
    return (
      <div className='right_container'>
        {this.RenderRightRed()}
        {this.RenderRightBlue()}
        <Dialog
          type='room'
          visible={roomVisible}
          cancelClick={() => this.setState({ roomVisible: false })}
          btnTxt={["免费量房"]}
          saveInfo={this.SaveInfo.bind(this)}
        />
        <Dialog
          type='price'
          visible={priceVisible}
          cancelClick={() => this.setState({ priceVisible: false })}
          btnTxt={["计算价格"]}
          saveInfo={this.SaveInfo.bind(this)}
        />
        <Dialog
          type='designer'
          visible={designerVisible}
          cancelClick={() => this.setState({ designerVisible: false })}
          btnTxt={["立即预约"]}
          saveInfo={this.SaveInfo.bind(this)}
        />
      </div>
    );
  }
  componentDidMount() {
    this.get(`${this.api.getwebSiteAdmin}?id=${1}`, "").then(
      (res) => {
        if (res.code === 0 && res.data) {
          if(res.data.status===0){
            rightRed.style.display = "none";
            rightBlue.style.display = "block";
          }
        }
      }
    );
    this.get(`${this.api.getwebSiteAdmin}?id=${2}`, "").then(
      (res) => {
        if (res.code === 0 && res.data) {
          if(res.data.status===0){
            rightRed.style.display = "none";
            rightBlue.style.display = "block";
          }
        }
      }
    );
    // 右侧红色背景导航栏，点击关闭
    var guanbi = document.getElementById("guanbi");
    guanbi.onmouseover = function () {
      guanbi.style.cursor = "pointer";
    };
    var rightRed = document.getElementsByClassName("right_top")[0];
    var rightBlue = document.getElementsByClassName("rightBlue")[0];
    guanbi.onclick = function () {
      rightRed.style.display = "none";
      rightBlue.style.display = "block";
    };
  }
}
export default withRouter(Rightred);
